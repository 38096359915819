<template>
    <div>
        <!--外层列表-->
        <page-list ref="packageInfo"
                   :package-list="packageList"
                   :search-to-props="searchToProps"
                   :is-show-result-num="false"
                   :span="8"
                   @search="search"
                   @clickItem="clickItem"
                   style="margin-top: -20px"
        >
            <!--单个学习包-->
            <template v-slot="scope">
                <package-card :item="scope.item"/>
            </template>
        </page-list>
    </div>
</template>

<script>
import PageList from "../components/pageList";
import PackageCard from "../components/packageCard";

export default {
    name: "course",
    components: {PackageCard, PageList},
    data() {
        return {
            packageList: [],
            // 搜索字段映射
            searchToProps: {
                package_type: {
                    label: undefined,
                    type: 'selectOption',
                    value: -1,
                    options: [
                        {label: '全部', value: -1},
                        {label: '法硕', value: 0},
                        {label: '学硕', value: 1},
                        {label: '通识课', value: 2}
                    ]
                }
            }
        }
    },
    methods: {
        // 搜索数据
        search(data) {
            data['type'] = data['search'][0].value
            // 向后端发送请求数据
            this.$api.post('/auth/info/getMyPackageList', data).then(res => {
                this.packageList = res.data.records
                this.$refs.packageInfo.updateTotal(res.data.total)
            }).catch(err => {
                console.log(this.$route.meta.title + '数据获取失败', err)
            })
        },
        // 点击数据
        clickItem(item) {
            this.$router.push('/package/detail/' + item.id)
        }
    }
}
</script>

<style scoped lang="scss">

</style>